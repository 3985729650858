/* eslint-disable vue/no-deprecated-destroyed-lifecycle */
<template>
  <div v-if="request" class="request">
    <v-app-bar dark fixed>
      <v-btn icon class="hidden-xs-only">
        <v-icon @ @click="$router.go(-1)">mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title>
        {{ $t('request.request') }}
        <strong>{{ request.sapId }}</strong> &nbsp;
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        v-if="currentStatus.status === statuses.RESERVED.code"
        color="green"
        class="mr-4"
        :outlined="!isComplete"
        @click="handleCompleteClick()"
      >
        <v-icon dark left>mdi-check-all</v-icon>
        {{ $t('action.complete') }}
      </v-btn>
      <v-btn
        v-if="currentStatus.status === statuses.RESERVED.code"
        color="error"
        class="mr-4"
        @click="handlePauseClick()"
      >
        <v-icon dark left>mdi-cancel</v-icon>
        {{ $t('action.pause') }}
      </v-btn>

      <v-btn
        v-if="currentStatus.status === statuses.OPEN.code"
        color="secondary"
        class="mr-4 black--text"
        @click="handleReserveRequest()"
      >
        <v-icon dark left>mdi-gesture-double-tap</v-icon>
        {{ $t('action.reserve') }}
      </v-btn>
      <v-btn
        v-if="currentStatus.status === statuses.PAUSED.code"
        color="secondary"
        class="mr-4 black--text"
        @click="handleReserveRequest()"
      >
        <v-icon dark left>mdi-play</v-icon>
        {{ $t('action.resume') }}
      </v-btn>

      <span>
        <v-icon>mdi-account-outline</v-icon>
        {{ isAuthenticated.profile.given_name }} {{ isAuthenticated.profile.family_name }}
      </span>
      <v-btn icon color="gray" @click="handleLogout">
        <v-icon>mdi-export</v-icon>
      </v-btn>
    </v-app-bar>
    <v-container v-if="request" class="grey lighten-5 pt-10">
      <v-row>
        <v-col cols="12">
          <v-card class="mt-5 px-5 py-5">
            <v-form ref="form" v-model="valid" :lazy-validation="true">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :value="`${request.sapId}, ${request.date}, ${request.time}`"
                    :label="$t('request.details.idDate')"
                    readonly
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="6">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            :value="`${request.quantity} ${request.storageUnitTypeDesc} ${request.contentDesc}`"
                            :label="$t('request.details.request')"
                            readonly
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <v-chip
                            :color=" getStatusProperties(request).color"
                          >{{ $t(getStatusProperties(request).name) }}</v-chip>
                        </v-col>
                        <v-col
                          v-if="request.latestStatus.userName && request.latestStatus.userName.length"
                          cols="3"
                        >
                          <v-chip color="primary">{{ request.latestStatus.userFriendlyName }}</v-chip>
                        </v-col>
                        <v-col cols="3">
                          <v-chip v-if="request.type" :color="requestTypes[request.type].color">
                            <v-avatar left>
                              <v-icon>{{ requestTypes[request.type].icon }}</v-icon>
                            </v-avatar>
                            {{ request.typeDesc }}
                          </v-chip>
                        </v-col>
                        <v-col cols="3">
                          <v-btn v-if="request.doorOpening" x-small fab color="yellow">
                            <v-icon class="black--text" v-text="'mdi-boom-gate-alert'" />
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="6">
                      <v-textarea
                        :value="requestProperties"
                        :label="$t('request.details.props')"
                        readonly
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    :value="request.comment"
                    :label="$t('request.details.comment')"
                    readonly
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    :value="`${request.fromZone} ${request.fromBin} > ${request.destZone} ${request.destBin}`"
                    :label="$t('request.details.fromTo')"
                    readonly
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :value="$t('generic.'+request.doorOpening)"
                    :label="$t('request.details.gateOpening')"
                    readonly
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    :value="`${request.ordName} (${request.ordPhone})`"
                    :label="$t('request.details.sender')"
                    readonly
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    :value="`${request.recName} (${request.recPhone})`"
                    :label="$t('request.details.receiver')"
                  >
                    readonly
                    />
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-if="request.reference"
                    :value="request.reference"
                    :label="$t('request.details.reference')"
                    readonly
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="mt-5 px-5 py-5">
            <v-list subheader>
              <v-subheader>{{ $t('request.details.items') }}</v-subheader>

              <v-list-item v-for="item in request.items" :key="item.id">
                <v-list-item-avatar>
                  <v-icon
                    class="orange lighten-1 white--text"
                    v-text="item.position ? `mdi-numeric-${item.position}-box-outline` : 'mdi-slash-forward-box'"
                  />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-text="`${item.quantity || '?'} ${item.storageUnitTypeDesc}`"
                  />
                  <v-list-item-subtitle v-text="item.contentDesc" />
                  <v-list-item-subtitle v-if="item.comment" v-text="item.comment" />
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn v-if="item.verification && !item.verified" icon>
                    <v-icon color="primary">mdi-qrcode-scan</v-icon>
                  </v-btn>
                  <v-btn v-if="item.verified" icon>
                    <v-icon color="success">mdi-checkbox-marked</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-timeline align-top dense>
              <v-timeline-item v-for="(st, index) in statusHistory " :key="st.index">
                <template #icon>
                  <v-avatar>
                    <img
                      :src="`https://eu.ui-avatars.com/api/?background=2272B5&color=fff&font-size=0.33&name=${st.userFriendlyName}`"
                    />
                  </v-avatar>
                </template>

                <v-row>
                  <v-col cols="2" class="py-1">
                    <v-chip
                      :color=" statuses[statusMap[st.status]].color"
                      class
                    >{{ $t(statuses[statusMap[st.status]].name) }}</v-chip>
                  </v-col>
                  <v-col cols="3">{{ st.userFriendlyName }}</v-col>
                  <v-col cols="3">{{ st.statusTime | dateTime }}</v-col>
                  <v-col cols="3">{{ st.comment }}</v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="pauseDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>{{ $t('request.pauseRequest') }}</v-card-title>
        <v-container>
          <v-row>
            <v-col sm="12">
              <v-form ref="pauseForm" v-model="pauseFormValid">
                <v-text-field
                  v-model="reason"
                  :label="$t('request.reasonForPause')"
                  :rules="[v => !!v || 'Reason is required']"
                  required
                ></v-text-field>
              </v-form>
            </v-col>
            <v-col sm="12">
              <v-chip v-for="(r, index) in pauseReasons" :key="index" @click="reason = r">{{ r }}</v-chip>
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <v-card-actions>
          <v-btn color="primary" @click="pauseDialog = false">{{ $t('generic.cancel') }}</v-btn>
          <v-spacer />
          <v-btn color="error" @click="handlePauseRequest()">{{ $t('action.pause') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="completeDialog" width="500">
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >{{ $t('request.completeRequest') }} {{ request.sapId }}</v-card-title>
        <v-container>
          <v-row>
            <v-col>
              <v-alert
                v-if="!isComplete"
                type="error"
                border="top"
                color="warning"
                dense
                dark
              >{{ $t('request.scanAllItems') }}</v-alert>
              <v-alert
                v-if="isDestinationScanRequired"
                type="error"
                border="top"
                color="error"
                dense
                dark
              >{{ $t('request.scanDestination') }}</v-alert>
              <br />
              <span>{{ $t('request.confirmDelivery', { req: request.sapId , zone: request.destZone, bin: request.destBin }) }}</span>
              <v-form ref="completeForm" v-model="pauseFormValid">
                <v-text-field v-model="reason" :label="$t('request.completeComment')"></v-text-field>

                <v-chip-group column>
                  <v-chip
                    v-for="(r, index) in completeReasons"
                    :key="index"
                    @click="reason = r"
                  >{{ r }}</v-chip>
                </v-chip-group>
                <template v-if="smsNotifacationPossible">
                  <v-checkbox
                    v-for="(rc, index) in notifyRecipients"

                    :key="index"
                    v-model="rc.notify"
                    :label="$t('request.sendSms')"
                  ></v-checkbox>
                </template>

              </v-form>
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <v-card-actions>
          <v-btn color="primary" @click="completeDialog = false">{{ $t('generic.cancel') }}</v-btn>
          <v-spacer />
          <v-btn color="green" @click="handleCompleteRequest()">{{ $t('generic.confirm') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { statuses, statusMap, requestTypes } from '@/helpers'
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'RequestDetails',
  data: () => ({
    reason: '',
    pauseFormValid: false,
    completeFormValid: false,
    reasonSelection: 0,
    completeReasons: ['Dellevererad till PORT 202/207', 'Dellevererad till PORT HA163', 'Levererad utanför', 'Dellevererad'],
    pauseReasons: ['4Å', 'Pallsök', 'Trailer', 'Utsida', 'Levplats', 'Asea varm', 'Dragarvagn', 'Fordonshall'],
    notifyRecipients: [{ name: 'receiver', notify: true }],
    statuses,
    statusMap,
    requestTypes,
    pauseDialog: false,
    completeDialog: false,
    scanner: false,
    valid: true,
    snackbar: false,
    isDestinationScanned: false
  }),

  computed: {
    smsNotifacationPossible () {
      return this.request.recPhone && /^[0,+]/g.test(this.request.recPhone)
    },
    ...mapGetters({
      isFetching: 'isFetching'
    }),
    userName () {
      return this.isAuthenticated.userName.split('@')[0]
    },
    isComplete () {
      return this.request.items
        ? !this.request.items.some(i => (i.verification === 'X' || i.verification === 'G') && !i.verified)
        : true
    },
    isDestinationScanRequired () {
      return !this.isDestinationScanned && this.request.items.some(i => i.verification === 'X' || i.verification === 'P')
    },
    request () {
      return this.$store.state.requests.find(rq => rq.id === this.$route.params.id)
    },
    currentStatus () {
      return this.request.statusHistory[this.request.statusHistory.length - 1]
    },
    statusHistory () {
      return [...this.request.statusHistory].reverse()
    },
    requestProperties () {
      return this.request.properties.reduce((acc, prop) => {
        acc += prop.propertyDesc
        acc += '\n'
        return acc
      }, '')
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  created () {
    this.$eventBus.$on('item-scanned', this.handleItemScan)
    this.$eventBus.$on('destination-scanned', this.handleDestinationScan)
  },
  destroyed () {
    this.$eventBus.$off('item-scanned', this.handleItemScan)
    this.$eventBus.$off('destination-scanned', this.handleDestinationScan)
  },
  methods: {
    ...mapActions(['postStatus', 'notify', 'createMessage', 'verifyItem', 'verifyDestination']),
    async handleItemScan (code) {
      const item = this.request.items.find(i => i.verificationId === code)
      if (item) {
        await this.verifyItem({ item, code })
        this.handleCompleteClick()
      } else {
        this.createMessage({ color: 'error', text: this.$t('notification.itemNotFound') })
      }
    },
    handleDestinationScan (code) {
      if (this.request.destBin === code) {
        this.isDestinationScanned = true
        this.createMessage({ color: 'success', text: this.$t('notification.destinationVerified') })
      } else {
        this.createMessage({ color: 'error', text: this.$t('notification.destinationIncorrect') })
      }
    },
    handleLogout () {
      this.$adal.logout()
    },
    formatTime (value) {
      return dayjs(value).format('HH:mm')
    },
    formatDateTime (value) {
      return value && dayjs(value).format('YYYY-MM-DD HH:mm')
    },
    getStatusProperties (tr) {
      return this.currentStatus ? statuses[statusMap[this.currentStatus.status]] : {}
    },
    validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    handlePauseClick () {
      this.pauseDialog = true
    },
    handleCompleteClick () {
      this.completeDialog = true
    },
    handlePauseRequest () {
      if (!this.$refs.pauseForm.validate()) return

      const status = {
        comment: this.reason,
        status: statuses.PAUSED.code
        // userName: this.userName
      }
      this.postStatus({ requestId: this.request.id, status })

      this.request = null
      this.reason = ''
      this.pauseDialog = false
    },
    handleCompleteRequest () {
      if (!this.$refs.completeForm.validate()) return
      const status = {
        comment: this.reason,
        status: statuses.COMPLETED.code
        // userName: this.userName
      }

      this.notifyRecipients.filter(nr => nr.notify).forEach(rn => {
        this.notify({ requestId: this.request.id, recipient: rn.name, message: this.$t('request.smsContent', { req: this.request.sapId, loc: this.request.destBin, comment: this.reason }) })
      })

      this.postStatus({ requestId: this.request.id, status })

      this.request = null
      this.reason = ''
      this.completeDialog = false

      this.$router.go(-1)
    },
    handleReserveRequest () {
      const status = {
        comment: this.reason,
        status: statuses.RESERVED.code
        // userName: this.userName
      }
      this.postStatus({ requestId: this.request.id, status })

      this.request = null
      this.reason = ''
      this.dialog = false
    }
  }
}
</script>
<style>
/* .v-list-item__content,
.v-timeline-item__body {
  justify-content: left;
  text-align: left;
  display: grid;
}

.v-timeline--dense .v-timeline-item__body {
  max-width: 100%;
  width: 100%;
}
.v-list-item__content,
.v-timeline-item__body {
  justify-content: left;
  text-align: left;
  display: block;
} */
</style>
